


















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Station} from "@/models/station";
import {Customer} from "@/models/customer";

@Component({})
export default class InventoryDialogComponent extends Vue {

  @Prop({default: false})
  public showDialog!: boolean;

  @Prop({default: []})
  public stations!: Station[];

  public searchText = '';

  public headers: any[] = [{
    text: 'Station',
    value: 'name'
  }, {
    text: 'Anschrift',
    value: 'address'
  }, {
    text: 'PLZ',
    value: 'zipCode'
  }, {
    text: 'Stadt',
    value: 'city'
  }, {
    text: 'Kontaktperson',
    value: 'contactPerson'
  }, {
    text: 'E-Mail',
    value: 'mail'
  }, {
    text: 'Telefon',
    value: 'phone'
  }, {
    text: 'Anzahl Maschinen',
    value: 'machines.length'
  }, {
    text: 'Details',
    value: 'details'
  }];

  public closed() {
    this.$emit('closed');
  }

  public openDetails(station: Station) {
    this.$router.push({name: 'station_details_view', params: {stationId: station!.id,
        customerId: (station!.customer as Customer).id}});
  }
}

